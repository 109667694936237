import React from "react";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import {
  Chip,
  DropdownBlur,
  DropdownSelectContainer,
  RotatableArrow,
  StyledCheckIcon,
} from "./FilterDropDownSelectInput.styles";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const FilterDropDownSelectInputView = ({
  label = "",
  isSelected = false,
  customData = {},
  error = "",
  isOpen,
  handleChipClick,
  children,
  dropdownRef,
}) => {
  const { primaryColor = `#000`, secondaryColor = `#f5f5f5` } =
    customData || {};

  return (
    <>
      <DropdownSelectContainer ref={dropdownRef}>
        <Chip
          type="button"
          isSelected={isSelected}
          onClick={handleChipClick}
          primaryColor={primaryColor}
          secondaryColor={secondaryColor}
          error={error}
          data-analytics={[
            UIElements.BUTTON,
            `${label.toLowerCase()}_job_filters`,
          ]}
        >
          <RowDiv alignItems="center" gap="8px">
            {label}
            {isSelected ? (
              <StyledCheckIcon />
            ) : (
              <RotatableArrow isOpen={isOpen} />
            )}
          </RowDiv>
        </Chip>
        {children}
      </DropdownSelectContainer>
      {isOpen && <DropdownBlur />}
    </>
  );
};

export default FilterDropDownSelectInputView;
