import React, { useState, useEffect, useRef } from "react";
import { getSimilaritySortedOptions } from "@/outscal-commons-frontend/utils/stringOperations";
import DropDownView from "./DropDown.view";
import { isMobile } from "@/hooks/useMobileBreakPoint";
import MobileDropDownController from "./MobileDropDown/MobileDropDown.controller";
import JobsSectionService from "@/mvcComponents/Commons/Jobs/JobsData/JobsSection.service";
const PAGE_SIZE = 100;

function DropDownController({
  name,
  optionsList,
  width,
  label,
  value,
  customData,
  onChange,
  maxWidth,
  closeIfOpen,
  defaultList,
  filterAppliedLabel,
  clearFilters,
}) {
  const initialOptionsList =
    defaultList?.length > 0 ? defaultList : optionsList;
  const displayOptionsRef = useRef(initialOptionsList.slice(0, PAGE_SIZE));
  const { isMultiSelect = true } = customData || {};
  const [searchTerm, setSearchTerm] = useState("");
  const [isActive, setActive] = useState(false);

  const debouncedSearch = (searchQuery) => {
    let sortedOptions = getSimilaritySortedOptions(
      searchQuery,
      optionsList,
      value
    );
    if (searchQuery.length > 0) {
      displayOptionsRef.current = sortedOptions.slice(0, PAGE_SIZE);
    } else {
      displayOptionsRef.current = initialOptionsList.slice(0, PAGE_SIZE);
    }
  };

  const onOptionClick = (option) => {
    let newValue = [];
    const isValueSelected = value?.some((item) => item.id === option.id);
    if (isMultiSelect) {
      if (isValueSelected) {
        newValue = value?.filter((item) => item.id !== option.id);
      } else {
        let pushOption = {
          id: option.id,
          label: option.label,
        };
        if (option.logo) {
          pushOption["logo"] = option.logo;
        }
        newValue = [pushOption, ...value];
      }
    } else {
      if (!isValueSelected) {
        newValue = [{ id: option.id, label: option.label }];
      } else {
        newValue = [];
      }
    }
    onChange({
      target: {
        name,
        value: newValue,
      },
    });
  };

  const onClear = (e) => {
    e.stopPropagation();
    onChange({
      target: {
        name,
        value: [],
      },
    });
  };

  useEffect(() => {
    if (!isActive) {
      setActive(true);
    }
  }, [isActive, value]);

  const filteredOptions = customData?.showSearchInput
    ? displayOptionsRef.current.filter(
        (option) =>
          !value?.some((selectedOption) => selectedOption?.id == option.id)
      )
    : displayOptionsRef.current;

  return (
    <>
      {isMobile && !customData?.showSearchInput ? (
        <MobileDropDownController
          width={width}
          label={label}
          value={value}
          customData={customData}
          onClear={onClear}
          onOptionClick={onOptionClick}
          maxWidth={maxWidth}
          setSearchTerm={setSearchTerm}
          searchTerm={searchTerm}
          hasFiltersApplied={value && value.length > 0}
          displayOptions={[...displayOptionsRef.current]}
          active={isActive}
          closeIfOpen={closeIfOpen}
          name={name}
        />
      ) : (
        <DropDownView
          width={width}
          label={label}
          value={value}
          customData={customData}
          onClear={onClear}
          onOptionClick={onOptionClick}
          maxWidth={maxWidth}
          setSearchTerm={(searchText) => {
            debouncedSearch(searchText);
            setSearchTerm(searchText);
          }}
          searchTerm={searchTerm}
          hasFiltersApplied={value && value.length > 0}
          displayOptions={[...filteredOptions]}
          active={isActive}
          closeIfOpen={closeIfOpen}
          totalOptionsLen={optionsList.length}
          filterAppliedLabel={filterAppliedLabel}
        />
      )}
    </>
  );
}

export default DropDownController;
