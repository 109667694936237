import React from "react";
import { Div, RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import { convertSlugToString } from "@/outscal-commons-frontend/utils/stringOperations";
import { Image, MenuItem, OptionsList, RadioInput, RadioInputWrapper, SelectedCheckIcon } from "./DropDown.styles";

const DropdownOptionsList = ({
  sortedOptions,
  value,
  primaryColor,
  secondaryColor,
  onOptionClick,
  defaultLogo,
  isMultiSelect,
  isValueList = false,
  showSearchInput,
}) => {
  const handleImageError = (event) => {
    event.target.srcset = "";
    event.target.src = defaultLogo;
  };
  return (
    <OptionsList isValueList={isValueList}>
      {sortedOptions.map((option) => {
        const isSelected = value?.some((selected) => selected.id === option.id);

        return (
          <MenuItem
            key={option.id}
            secondaryColor={secondaryColor}
            isSelected={
              isSelected && isMultiSelect && (!showSearchInput || isValueList)
                ? true
                : false
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onOptionClick(option);
            }}
          >
            <RowDiv
              alignItems="center"
              gap="4px"
              justifyContent="space-between"
            >
              <RowDiv alignItems="center" gap="8px">
                {!isMultiSelect ? (
                  <RadioInputWrapper isSelected={isSelected}>
                    {isSelected ? <RadioInput /> : null}
                  </RadioInputWrapper>
                ) : null}
                {option?.logo ? (
                  <Image
                    src={option.logo}
                    alt={option.label + " logo"}
                    onError={handleImageError}
                  />
                ) : null}
                {option.label || convertSlugToString(option.id)}
              </RowDiv>
              {isMultiSelect &&
                (!showSearchInput || isValueList) &&
                value?.some((selected) => selected.id === option.id) && (
                  <Div>
                    <SelectedCheckIcon primaryColor={primaryColor} />
                  </Div>
                )}
            </RowDiv>
          </MenuItem>
        );
      })}
    </OptionsList>
  );
};

export default DropdownOptionsList;
