import { isMobile } from "@/hooks/useMobileBreakPoint";
import React, { useRef, useEffect } from "react";
import styled from "styled-components";

const StyledInput = styled.input`
  width: 100%;
  font-size: ${(p) => p.theme.fontSize.sm};
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  border: 2px solid ${(p) => p.theme.colors.grey} !important;
  border-radius: 8px;
  &:focus {
    border-color: ${({ primaryColor }) => primaryColor} !important;
  }
  @media screen and (max-width: 768px) {
    font-size: ${(p) => p.theme.fontSize.md};
  }
`;

const SearchInput = ({
  showSearchInput,
  searchTerm,
  setSearchTerm,
  primaryColor,
}) => {
  const inputRef = useRef(null);

  const handleInputChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFocus = (element) => {
    const node = element.currentTarget;
    setTimeout(() => {
      node.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }, 500); // Delay to allow keyboard to appear
  };

  useEffect(() => {
    if (showSearchInput && inputRef.current && !isMobile) {
      inputRef.current.focus();
    }
  }, [showSearchInput]);

  return (
    showSearchInput && (
      <StyledInput
        ref={inputRef}
        onFocus={handleFocus}
        type="text"
        placeholder="Search"
        value={searchTerm}
        onChange={handleInputChange}
        primaryColor={primaryColor}
      />
    )
  );
};

export default SearchInput;
