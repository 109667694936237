import React from "react";
import classes from "./MobileDropDown.module.css";
import PillOptionView from "./PillOption/PillOption.view";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const MobileDropDownView = ({
  label,
  value,
  customData,
  onOptionClick,
  onClear,
  displayOptions,
  closeIfOpen,
  hasFiltersApplied,
  name,
}) => {
  const showIcon = customData.isMultiSelect;

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <p className={classes.filterName}>{label}</p>
        {hasFiltersApplied && customData.isMultiSelect ? (
          <div
            className={classes.reset}
            onClick={onClear}
            data-analytics={[
              UIElements.BUTTON,
              `clear_${label.toLowerCase()}_job_filters`,
            ]}
          >
            Clear
          </div>
        ) : null}
      </div>
      <div className={classes.body}>
        <div className={classes.optionsList}>
          {displayOptions?.map((option) => (
            <PillOptionView
              option={option}
              key={option.id}
              showIcon={showIcon}
              isSelected={value?.some((selected) => selected.id == option.id)}
              onOptionClick={() => {
                onOptionClick(option);
              }}
              name={name}
            />
          ))}
        </div>
        <button
          onClick={closeIfOpen}
          className={classes.applyFilterCTA}
          data-analytics={[
            UIElements.BUTTON,
            `mobile_apply_${label.toLowerCase()}_job_filters`,
          ]}
        >
          Show Results
        </button>
      </div>
    </div>
  );
};

export default MobileDropDownView;
