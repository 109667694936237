import styled from "styled-components";
import { FaCheckCircle } from "react-icons/fa";
import { Div } from "@/outscal-commons-frontend/Styled/LayoutUtils";

export const Dropdown = styled.div`
  position: absolute;
  z-index: 100;
  top: calc(100% + 3px);
  min-width: ${(p) => p.width};
  max-width: ${(p) => p.maxWidth};
  border-radius: 8px;
  border-top: none;
  background-color: ${(p) => p.theme.colors.white};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  transition: bottom 0.5s ease, top 0.5s ease;
  @media (max-width: 800px) {
    position: fixed;
    z-index: 100;
    bottom: -100%;
    left: 0;
    right: 0;
    width: 100%;
    max-width: 100%;
    margin: auto;
    top: 100%;
    &.active {
      bottom: 0px;
      top: 50px;
    }
  }
`;

export const DropdownContent = styled.div`
  margin-bottom: 8px;
  height: min-content;
  max-height: 300px;
  overflow-y: scroll;
  overflow-x: hidden;
  @media screen and (max-width: 800px) {
    max-height: calc(84vh - 200px);
  }

  &::-webkit-scrollbar {
    width: 7px;
    display: flex;
  }
  &::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
    background: ${(p) => p.primaryColor};
    border-radius: 4px;
  }
`;

export const Separator = styled.hr`
  background-color: ${(p) => p.theme.colors.textDarkerGrey};
  height: 1px;
  margin: 10px 0 10px 0;
`;

export const OptionsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  ${(p) =>
    p.isValueList == true &&
    `position:sticky;
  top:0px;
  border-bottom:1px solid ${p.theme.colors.borderGray};
  box-shadow: -2px -2px 5px 4px rgba(0, 0, 0, 0.17);
  max-height:100px;
  overflow-y:scroll;
    &::-webkit-scrollbar {
  width: 5px;
   display: flex;
   }
  &::-webkit-scrollbar-track {
  background: #f1f1f1;
  }
  &::-webkit-scrollbar-thumb {
  background: #499c77;
  border-radius: 4px;
  }
  @media screen and (max-width:767px){
    max-height:120px;
  }
`}
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
  object-fit: cover;
`;

export const MenuItem = styled.li`
  padding: 8px 12px;
  cursor: pointer;
  background-color: ${({ isSelected, secondaryColor }) =>
    isSelected == true ? secondaryColor : "inherit"};
  &:hover {
    background-color: ${({ isSelected, secondaryColor }) =>
      isSelected == true ? secondaryColor : "#f5f5f5"};
  }
`;

export const SelectedCheckIcon = styled(FaCheckCircle)`
  color: ${({ primaryColor }) => primaryColor};
`;

export const RadioInput = styled(Div)`
  background-color: ${(p) => p.theme.colors.white};
  border: 1px solid ${(p) => p.theme.colors.textDarkerGrey};
  border-radius: ${(p) => p.theme.borderRadius.round};
  position: absolute;
  width: 9px;
  height: 8.7px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const RadioInputWrapper = styled(Div)`
  position: relative;
  border: 1px solid ${(p) => p.theme.colors.textDarkerGrey};
  border-radius: ${(p) => p.theme.borderRadius.round};
  width: 18px;
  height: 17.5px;
  ${(p) =>
    p.isSelected &&
    `
display: flex;
justify-content: center;
align-items: center;
background-color: ${p.theme.colors.absoluteGreen};`}
`;
