import React from "react";
import { IoAddSharp } from "react-icons/io5";
import { FaCheck } from "react-icons/fa6";
import classes from "./PillOption.module.css";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const PillOptionView = ({
  option,
  showIcon,
  onOptionClick,
  isSelected,
  name,
}) => {
  const { id, label } = option;

  return (
    <div
      id={id}
      className={` ${isSelected ? classes.selectedRoot : ""} ${classes.root}`}
      onClick={onOptionClick}
      data-analytics={[
        UIElements.TEXT,
        `mobile_filter_pill_${name.toLowerCase()}`,
      ]}
    >
      {label}
      <div
        className={`${
          showIcon && !isSelected ? classes.iconWrapper : classes.hideIcon
        }  `}
      >
        <IoAddSharp />
      </div>
      <div
        className={`${
          showIcon && isSelected ? classes.iconWrapper : classes.hideIcon
        }  `}
      >
        <FaCheck />
      </div>
    </div>
  );
};

export default PillOptionView;
