import React, { useState } from "react";
import FilterDropDownSelectInputController from "./FilterDropDownSelectInput/FilterDropDownSelectInput.controller";
import { ClearButton, FiltersDiv } from "./JobFilters.styles";
import { isiPhone } from "@/utils/commonUtils";
import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";

const JobFiltersView = ({
  filterUIObj,
  filters,
  clearFilters,
  disableFiltersClear = false,
  setFilters,
  setFiltersInQuery,
}) => {
  return (
    <FiltersDiv isIphone={isiPhone()}>
      {filterUIObj.map((item) => {
        return (
          <FilterDropDownSelectInputController
            setFilters={setFilters}
            setFiltersInQuery={setFiltersInQuery}
            filters={filters[item.name] || []}
            key={item.name}
            name={item.name}
            label={item.label}
            optionsList={item.optionsList}
            defaultList={item.defaultList}
            customData={item.customData}
            filterAppliedLabel={item.filterAppliedLabel}
            width="300px"
            clearFilters={clearFilters}
          />
        );
      })}

      <ClearButton
        disableFiltersClear={disableFiltersClear}
        onClick={() => {
          clearFilters();
        }}
        data-analytics={[UIElements.BUTTON, "clear_job_filters"]}
      >
        Clear
      </ClearButton>
    </FiltersDiv>
  );
};

export default JobFiltersView;
