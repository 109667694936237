import React from "react";
import DropdownHeader from "./DropdownHeader";
import DropdownOptionsList from "./DropdownOptionsList";
import { Dropdown, DropdownContent } from "./DropDown.styles";
import { isMobile } from "@/hooks/useMobileBreakPoint";
import BottomFilterActionsView from "./BottomFilterActions.view";

function DropDownView({
  width,
  label,
  value,
  customData,
  onOptionClick,
  onClear,
  maxWidth,
  setSearchTerm,
  searchTerm,
  displayOptions,
  active,
  closeIfOpen,
  hasFiltersApplied,
  totalOptionsLen,
  filterAppliedLabel,
}) {
  const {
    showSearchInput = false,
    showDropdownHeader = true,
    primaryColor = `#000`,
    secondaryColor = `#f5f5f5`,
    defaultLogo = "",
    isMultiSelect,
  } = customData || {};

  const handleClearAll = (e) => {
    setSearchTerm("");
    onClear(e);
  };

  return (
    <>
      <Dropdown
        className={active ? "active" : ""}
        maxWidth={maxWidth}
        width={width}
      >
        {showDropdownHeader && (
          <DropdownHeader
            closeIfOpen={closeIfOpen}
            label={label}
            showSearchInput={showSearchInput}
            searchTerm={searchTerm}
            setSearchTerm={setSearchTerm}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            error={""}
            clearAll={handleClearAll}
            hasFiltersApplied={hasFiltersApplied}
            displayOptionsLen={displayOptions.length}
            totalOptionsLen={totalOptionsLen}
            filterAppliedLabel={filterAppliedLabel}
            selectedOptionsLen={value.length}
          />
        )}
        <DropdownContent primaryColor={primaryColor}>
          {value.length > 0 && showSearchInput ? (
            <DropdownOptionsList
              sortedOptions={value}
              value={value}
              primaryColor={primaryColor}
              secondaryColor={secondaryColor}
              defaultLogo={defaultLogo}
              onOptionClick={(option) => {
                onOptionClick(option);
              }}
              isMultiSelect={isMultiSelect}
              isValueList={true}
            />
          ) : null}
          <DropdownOptionsList
            sortedOptions={displayOptions}
            value={value}
            primaryColor={primaryColor}
            secondaryColor={secondaryColor}
            defaultLogo={defaultLogo}
            onOptionClick={(option) => {
              onOptionClick(option);
            }}
            isMultiSelect={isMultiSelect}
            showSearchInput={showSearchInput}
          />
          {isMobile && (
            <BottomFilterActionsView
              closeIfOpen={closeIfOpen}
              handleClearAll={handleClearAll}
              label={label}
            />
          )}
        </DropdownContent>
      </Dropdown>
    </>
  );
}

export default DropDownView;
