import { UIElements } from "@/outscal-commons-frontend/Hooks/useAnalyticsClickEvent";
import { RowDiv } from "@/outscal-commons-frontend/Styled/LayoutUtils";
import React from "react";
import styled from "styled-components";

const Root = styled(RowDiv)`
  justify-content: space-between;
  background-color: ${(p) => p.theme.colors.white};
  @media screen and (max-width: 1000px) {
    position: fixed;
    bottom: 55px;
    left: 8px;
    right: 8px;
  }
`;

const PrimaryCTA = styled.button`
  background-color: #3f57cb;
  color: white;
  border: 1px solid #d8d8d8;
  padding: 8px 0px;
  font-size: 16px;
  border-radius: 10px;
  width: 45%;
`;

const SecondaryCTA = styled.button`
  border: 1px solid #d8d8d8;
  padding: 8px 0px;
  font-size: 16px;
  border-radius: 10px;
  font-weight: ${(p) => p.theme.fontWeight.medium};
  background-color: ${(p) => p.theme.colors.backgroundGrey};
  width: 45%;
`;

const BottomFilterActionsView = ({ closeIfOpen, handleClearAll, label }) => {
  return (
    <Root>
      <SecondaryCTA
        onClick={handleClearAll}
        data-analytics={[
          UIElements.BUTTON,
          `bottom_clear_${label.toLowerCase()}_job_filters`,
        ]}
      >
        Clear
      </SecondaryCTA>
      <PrimaryCTA
        onClick={closeIfOpen}
        data-analytics={[
          UIElements.BUTTON,
          `bottom_apply_${label.toLowerCase()}_job_filters`,
        ]}
      >
        Apply
      </PrimaryCTA>
    </Root>
  );
};

export default BottomFilterActionsView;
