import React, { useState, useRef, useEffect } from "react";
import DropDownController from "./DropDown/DropDown.controller";
import FilterDropDownSelectInputview from "./FilterDropDownSelectInput.view";
import { useClickOutside } from "@/outscal-commons-frontend/Hooks/useClickOutside";

const FilterDropDownSelectInputController = ({
  name = "",
  label = "",
  optionsList = [],
  customData = {},
  width = "240px",
  defaultList,
  filterAppliedLabel,
  filters,
  setFilters,
  setFiltersInQuery,
  clearFilters,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useClickOutside(dropdownRef, () => {
    setIsOpen(false);
    if (isOpen) {
      setFiltersInQuery();
    }
  });
  const { maxWidth = "300px" } = customData || {};

  const handleChipClick = (e) => {
    setIsOpen(!isOpen);
    if (isOpen) {
      setFiltersInQuery();
    }
  };

  return (
    <>
      <FilterDropDownSelectInputview
        label={label}
        isSelected={filters?.length > 0}
        customData={customData}
        isOpen={isOpen}
        dropdownRef={dropdownRef}
        handleChipClick={handleChipClick}
      >
        {isOpen && (
          <DropDownController
            defaultList={defaultList}
            clearFilters={clearFilters}
            maxWidth={maxWidth}
            label={label}
            width={width}
            name={name}
            value={filters || []}
            optionsList={[...optionsList]}
            customData={customData}
            closeIfOpen={handleChipClick}
            onChange={(e) => {
              setFilters(e.target.name, e.target.value);
            }}
            filterAppliedLabel={filterAppliedLabel}
          />
        )}
      </FilterDropDownSelectInputview>
    </>
  );
};

export default FilterDropDownSelectInputController;
