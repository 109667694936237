import React from "react";
import MobileDropDownView from "./MobileDropDown.view";

const MobileDropDownController = ({
  width,
  label,
  value,
  customData,
  onOptionClick,
  onClear,
  maxWidth,
  setSearchTerm,
  searchTerm,
  displayOptions,
  active,
  closeIfOpen,
  name,
}) => {
  return (
    <MobileDropDownView
      width={width}
      label={label}
      value={value}
      customData={customData}
      onClear={onClear}
      onOptionClick={onOptionClick}
      maxWidth={maxWidth}
      setSearchTerm={setSearchTerm}
      searchTerm={searchTerm}
      hasFiltersApplied={value && value.length > 0}
      displayOptions={displayOptions}
      active={active}
      closeIfOpen={closeIfOpen}
      name={name}
    />
  );
};

export default MobileDropDownController;
